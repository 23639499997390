<script setup lang="ts">
import { useLocalizationStore } from '~/store/localization/localization'

const localizationStore = useLocalizationStore()
const { locale } = useI18n()

onMounted(() => {
  // localizationStore.resetState();
  locale.value = localizationStore.getSelectedLanguage().literal
})
</script>

<template>
  <v-app>
    <div class="d-flex justify-center flex-column align-center h-100">
      <slot />
    </div>
  </v-app>
</template>
